import { render, staticRenderFns } from "./addCompany.vue?vue&type=template&id=749d5968&scoped=true&"
import script from "./addCompany.vue?vue&type=script&lang=js&"
export * from "./addCompany.vue?vue&type=script&lang=js&"
import style0 from "./addCompany.vue?vue&type=style&index=0&id=749d5968&prod&lang=scss&scoped=true&"
import style1 from "./addCompany.vue?vue&type=style&index=1&id=749d5968&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "749d5968",
  null
  
)

export default component.exports