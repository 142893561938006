<template>
<div id="addCompany">
  <div class="step-box">
    <h3>开启高效的招聘方式，你只需要 3步：</h3>
    <div class="steps">
      <div class="step-one">
        <p>
          <span>1</span>
          <label>创建名片</label>
        </p>
      </div>
      <div class="line"></div>
      <div class="step-one">
        <p>
          <span>2</span>
          <label>创建公司</label>
        </p>
      </div>
      <div class="line lineGray"></div>
      <div class="step-three">
        <p>
          <span>3</span>
          <label>发布第一个职位</label>
        </p>
      </div>
    </div>
  </div>
  <!-- 创建公司 -->
  <div class="addCompany-ct">
    <div class="attestion">
      <h3>创建公司</h3>
      <p>公司信息将被审核，请确保你输入的信息真实有效</p>
      <el-upload class="license-uploader" :on-success="licenseSuccess" :action="baseUrl" :show-file-list="false">
        <img v-if="companyModel.businessUrl" :src="companyModel.businessUrl" class="license-pic">
        <div class="license-btn" v-else>
          <img src="@/assets/imgs/company/upload-btn.png" />
          <span>上传营业执照/组织机构代码证</span>
        </div>
      </el-upload>
    </div>
    <div class="publishCompany">
      <el-form :inline="true" :model="companyModel" :rules="rules" ref="ruleForm" label-width="100px">
        <el-form-item label="企业logo" class="avator-item">
          <el-upload class="avatar-uploader" :on-success="logoSuccess" :action="baseUrl" :show-file-list="false">
            <img v-if="companyModel.enterpriseLogo" :src="companyModel.enterpriseLogo" class="avatar">
             <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
        <el-form-item label="公司名称">
          <el-input :disabled="true" v-model="companyModel.enterpriseName" placeholder="请输入公司名称"></el-input>
        </el-form-item>
        <el-form-item label="公司简称" prop="abbreviation">
          <el-input v-model="companyModel.abbreviation" placeholder="请输入公司简称"></el-input>
        </el-form-item>
        <el-form-item label="详细地址" prop="detailAddress">
          <el-input v-model="companyModel.detailAddress" placeholder="请输入详细地址"></el-input>
        </el-form-item>
        <el-form-item label="企业网址" prop="companyWebsite">
          <el-input v-model="companyModel.companyWebsite" placeholder="请输入企业网址"></el-input>
        </el-form-item>
        <el-form-item label="企业邮箱" prop="enterpriseMailbox">
          <el-input v-model="companyModel.enterpriseMailbox" placeholder="请输入企业邮箱"></el-input>
        </el-form-item>
        <el-form-item label="从事行业" prop="industry">
          <el-cascader v-model="companyModel.industry" @change="selectIndustry" placeholder="请选择从事行业" :options="industryList" :props="{
              value:'code',label:'name',children:'subLevelModelList'
            }" :show-all-levels="false"></el-cascader>
        </el-form-item>
        <el-form-item label="公司福利" prop="detailAddress">
          <el-select v-model="companyModel.materialBenefits" multiple placeholder="请选择公司福利">
            <el-option v-for="item in benefitsList" :key="item.dictValue" :label="item.dictLabel" :value="item.dictValue">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="企业性质" prop="detailAddress">
          <el-select v-model="companyModel.enterpriseNature" placeholder="请选择企业性质">
            <el-option v-for="item in enterpriseNatureList" :key="item.dictValue" :label="item.dictLabel" :value="item.dictValue">
            </el-option>
          </el-select>
        </el-form-item>
         <el-form-item label="企业规模" prop="scale">
          <el-select v-model="companyModel.scale" placeholder="请选择企业规模">
            <el-option v-for="item in scaleList" :key="item.dictValue" :label="item.dictLabel" :value="item.dictValue">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="融资阶段" prop="financingStage">
          <el-select v-model="companyModel.financingStage" placeholder="请选择融资阶段">
            <el-option v-for="item in stageList" :key="item.dictValue" :label="item.dictLabel" :value="item.dictValue">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="所在地区" prop="financingStage">
           <el-cascader  v-model="companyModel.regions" :show-all-levels="true"  :options="regionList" placeholder="请选择所在区域">
            </el-cascader>
        </el-form-item>
        <el-form-item label="公司简介" prop="profile">
          <el-input type="textarea" maxlength="255" v-model="companyModel.profile" placeholder="请输入公司简介"></el-input>
        </el-form-item>
        <div class="saveBtn" @click="next">创建公司</div>
      </el-form>
    </div>
  </div>
</div>
</template>

<script>
import { getCompanyIndustry,getUrbanAreaList, getDictData, addEnterpriseInfo, addQualification } from "@/api/index.js";
export default {
  name: "addContacts",
  data() {
    return {
      companyModel: {
        businessUrl: '',
        enterpriseName: '',
        abbreviation: '',
        detailAddress: '',
        industry: '',
        materialBenefits: '',
        enterpriseNature: '',
        profile: '',
        enterpriseLogo:'',
        scale:'',
        financingStage:'',
        region:'',
        regions:[],
        companyWebsite:'',
        enterpriseMailbox:''
      },
      rules: {
        userName: [
          { required: true, message: '请输入您的姓名', trigger: 'blur' }
        ]
      },
      baseUrl: '',
      industryList: [],
      benefitsList: [],
      enterpriseNatureList: [],
      scaleList:[],
      stageList:[],
      regionList:[]
    }
  },
  mounted() {
    this.baseUrl = process.env.VUE_APP_BASE_API + '/webApi/common/upload'
    this.getCompanyIndustry()
    this.getAreaList()
    this.getDictData('company_benefits')
    this.getDictData('enterprise_nature')
    this.getDictData('scale_enterprises')
    this.getDictData('financing_stage')
    
    this.companyModel.enterpriseName = this.$route.query.enterpriseName
  },
  methods: {
    getAreaList(){
      getUrbanAreaList().then(res=>{
        this.regionList = res.data
      })
    },
    logoSuccess(res){
       this.companyModel.enterpriseLogo = res.url
       console.log(res)
    },
    selectIndustry(val) {
      this.companyModel.industry = val[1]
    },
    getDictData(dictType) {
      getDictData({
        dictType: dictType
      }).then(res => {
        if (dictType == 'company_benefits') {
          this.benefitsList = res.rows
        }
        if (dictType == 'enterprise_nature') {
          this.enterpriseNatureList = res.rows
        }
        if (dictType == 'scale_enterprises') {
          this.scaleList = res.rows
        }
        if (dictType == 'financing_stage') {
          this.stageList = res.rows
        }
        
      })
    },
    getCompanyIndustry() {
      getCompanyIndustry({}).then(res => {
        this.industryList = res.rows
      })
    },
    licenseSuccess(res) {
      this.companyModel.businessUrl = res.url
    },
    next() {
      console.log( this.companyModel.regions)
      
      if (this.companyModel.businessUrl) {
        addEnterpriseInfo({
          enterpriseName: this.companyModel.enterpriseName,
          abbreviation: this.companyModel.abbreviation,
          detailAddress: this.companyModel.detailAddress,
          industry: this.companyModel.industry,
          materialBenefits: this.companyModel.materialBenefits.join(','),
          enterpriseNature: this.companyModel.enterpriseNature,
          profile: this.companyModel.profile,
          enterpriseLogo: this.companyModel.enterpriseLogo,
          scale: this.companyModel.scale,
          financingStage: this.companyModel.financingStage,
          regions: this.companyModel.regions,
          companyWebsite: this.companyModel.companyWebsite,
          enterpriseMailbox: this.companyModel.enterpriseMailbox
        }).then(res => {
          if (res.code == 200) {
            addQualification({
              businessUrl: this.companyModel.businessUrl
            }).then(res => {
              if (res.code == 200) {
                this.$message({
                  message: '保存成功',
                  type: 'success'
                })
                this.$router.push({
                  path: '/verify'
                })

              } else {
                this.$message({
                  message: res.msg,
                  type: 'warning'
                })
              }
            })

          }else{
              this.$message({
                message: res.msg,
                type: 'warning'
              })
          }
        })
      } else {
        this.$message({
          message: '请上传营业执照',
          type: 'warning'
        })
        return
      }

    }
  }
};
</script>

<style lang="scss" scoped>
#addCompany {
  width: 100%;
  min-height: 814px;
  background: linear-gradient(0deg, #F5F5F5 1%, #D6E9FF 100%);
  padding-bottom: 20px;
}

.step-box {
  padding-top: 31px;
  width: 1200px;
  margin: 0 auto;
  overflow: hidden;

  h3 {
    font-size: 26px;
    font-weight: 400;
    color: #2A2C33;
    float: left;
    margin-top: 40px;
  }

}

.steps {
  float: right;
  height: 100px;

  .step-one {
    float: left;
    text-align: center;

    p {
      float: left;

      span {
        display: inline-block;
        width: 41px;
        height: 41px;
        background: #0077FF;
        border-radius: 50%;
        text-align: center;
        line-height: 41px;
        color: #fff;
        font-size: 18px;
      }

      label {
        display: block;
        font-size: 16px;
        color: #0077FF;
        margin-top: 10px;
      }
    }
  }

  .line {
    width: 119px;
    height: 2px;
    background: #0077FF;
    border-radius: 1px;
    float: left;
    margin-top: 21px;
  }

  .lineGray {
    background: #999999;
  }

  .step-two {
    float: left;
    margin-left: 8px;
    text-align: center;

    p {
      float: left;

      span {
        display: inline-block;
        width: 41px;
        height: 41px;
        background: #0077FF;
        border-radius: 50%;
        text-align: center;
        line-height: 41px;
        color: #fff;
        font-size: 18px;
      }

      label {
        display: block;
        font-size: 16px;
        color: #999999;
        margin-top: 10px;
      }
    }
  }

  .step-three {
    float: left;
    margin-left: 8px;

    p {
      float: left;

      span {
        display: inline-block;
        width: 41px;
        height: 41px;
        background: #999999;
        border-radius: 50%;
        text-align: center;
        line-height: 41px;
        color: #fff;
        font-size: 18px;
      }

      label {
        display: block;
        font-size: 16px;
        color: #999999;
        margin-top: 10px;
        position: relative;
        right: 70px;
      }
    }
  }
}

.addCompany-ct {
  width: 865px;
  margin: 31px auto;
  min-height: 430px;
  background: #FFFFFF;
  padding: 42px 100px 41px 100px;
  border-radius: 1px 10px 10px 10px;

  .attestion {
    text-align: center;

    h3 {
      font-size: 24px;
      font-weight: bold;
      color: #222222;
    }

    p {
      font-size: 16px;
      color: #222222;
      margin-top: 26px;
      margin-bottom: 20px;
    }

    .license-btn {
      width: 362px;
      height: 143px;
      background: #EAF4FF;
      border-radius: 5px;

      img {
        width: 40px;
        height: 40px;
        margin-top: 30px;
        margin-bottom: 13px;
      }

      span {
        display: block;
        font-size: 16px;
        color: #999999;
      }
    }

    .license-pic {
      width: 362px;
      height: 143px;
    }
  }

  .publishCompany {
    padding: 0;
    background: none;
    margin-top: 30px;
  }
}

.saveBtn {
  width: 592px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  font-size: 16px;
  color: #FFFFFF;
  background: #0077FF;
  border-radius: 5px;
  margin-left: 100px;
  cursor: pointer;
}
.uoloadPic,
  .avatar {
    height: 66px;
    width: 66px;
}

</style><style lang="scss">
.publishCompany {
  .el-form-item {
    margin-bottom: 30px;
    vertical-align:bottom
  }

  .el-form-item__label {
    font-size: 16px;
    color: #000000;
  }

  .el-input__inner {
    width: 320px;
    height: 40px;
    background: #F5F5F5;
    border-radius: 5px;
    border: none;
    font-size: 16px;
    color: #999999;
  }

  .el-textarea__inner {
    width: 750px;
    min-height: 120px !important;
    background: #F5F5F5;
    border-radius: 5px;
    border: none;
    font-size: 16px;
    color: #999999;
  }
}
.avator-item {
  width: 400px;
  margin-bottom: 15px !important;
  .el-form-item__label {
    margin-top: 13px;
  }
  .avatar-uploader-icon {
    font-size: 46px;
    color: #ddd;
    padding: 15px;
    border: 1px solid #ddd;
    border-radius: 6px;
  }
}
</style>
